@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/subset-RobotoSlab-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('../fonts/subset-RobotoSlab-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/subset-NotoSans-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/subset-NotoSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/subset-NotoSans-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Noto Sans';
    src: url('../fonts/subset-NotoSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    font: 13px "Noto Sans", sans-serif;
    color: $col-txt;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto Slab", sans-serif;
    font-weight: 700;
    color: $col-accent;
    line-height: 1.15em;
}

h1 {
    font-size: 48px;
    margin-top: 0;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
    margin-bottom: 13px;
}

@media screen and (max-width: 800px) {

    $multiplier: 0.85;

    h1 {
        font-size: calc(48px * $multiplier);
    }

    h2 {
        font-size: calc(36px * $multiplier);
    }

    h3 {
        font-size: calc(24px * $multiplier);
    }

    h4 {
        font-size: calc(18px * $multiplier);
    }

}

p {
    margin: 1em 0;
    line-height: 1.6em;
}

a {
    color: $col-accent-2;
    text-decoration: none;
}