main[data-template="contact"] {

    min-height: calc(100vh - 290px);
    padding-top: 160px;

    &>img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }


    &>section {
        max-width: 295px;
        left: 60%;
        position: relative;

        h1 {
            top: auto;
            left: auto;
            transform: none;
            text-align: left;
            margin: 0 0 10px;
        }

        [type="submit"] {
            display: block;
            margin: 30px auto;
        }

        .thankyou {
            h3 {
                margin-top: 80px;
            }
            p {
                color: $col-img-txt;
            }
        }
    }

    @media (max-width: 970px) {

        &>section {
            left: 50%;
        }

    }
    @media (max-width: 650px) {

        &>section {
            // display: block;
            left: auto;
            margin: 0 auto;
        }

    }

}