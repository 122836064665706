body>footer {
    
    position: relative;
    margin-top: 80px;
    z-index: 1;

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        height: 400px;
        background: linear-gradient(0deg, rgba(0, 0, 0, .35) 0%, rgba(0, 0, 0, 0) 100%);
        pointer-events: none;
        z-index: -1;
    }

    .card {
        display: grid;
        grid-template-columns: 65% auto;
        grid-gap: 40px;

        margin: 0 25px;
        padding: 30px 45px 40px;

        h4 {
            margin-top: 0;
        }

        .gallery {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;

            img {
                display: block;
                width: 60px;
                height: 60px;
                object-fit: cover;
                border-radius: 5px;
            }
        }

        address {
            font-style: normal;

            p {
                margin: 0;
            }
        }
    }

    .bottom {
        position: relative;
        
        .copyright {
            position: absolute;
            bottom: 10px;
            left: 13px;
            margin: 0;
            color: $col-img-txt;
            font-size: 11px;
        }

        nav {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;

            a {
                color: $col-img-txt;
                font-size: 11px;
                text-decoration: none;
                transition: color .2s;
                line-height: 44px;
            }
        }
    }

    #credentials {
        svg {
            display: block;
            position: absolute;
            bottom: 10px;
            right: 10px;
            height: 24px;
            width: 24px;
        }
    }

    @media (max-width: 820px) {

        .card {
            grid-template-columns: 1fr;
        }

        .bottom {
            
            nav {
                padding-bottom: 40px;
            }
        }

    }

    @media (max-width: 400px) {

        .card {
            padding: 20px 25px 25px;

            .gallery {
                gap: 10px;
            }
        }

    }
}