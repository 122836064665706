.logo_instagram {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.418 19.418"><path d="M9.709 1.75c2.592 0 2.9.01 3.924.057 2.631.12 3.86 1.368 3.98 3.98.047 1.023.056 1.331.056 3.923s-.01 2.9-.056 3.923c-.121 2.609-1.346 3.86-3.98 3.98-1.024.047-1.33.057-3.924.057s-2.9-.01-3.923-.057c-2.638-.121-3.86-1.375-3.98-3.981-.047-1.023-.057-1.33-.057-3.923s.011-2.9.057-3.923c.121-2.611 1.346-3.86 3.98-3.98C6.81 1.76 7.117 1.75 9.709 1.75Zm0-1.75c-2.637 0-2.967.011-4 .058C2.18.22.221 2.176.059 5.706.011 6.742 0 7.072 0 9.709s.011 2.968.058 4C.22 17.238 2.176 19.2 5.706 19.36c1.036.047 1.367.058 4 .058s2.968-.011 4-.058c3.523-.162 5.487-2.118 5.647-5.647.048-1.036.059-1.367.059-4s-.011-2.967-.058-4C19.2 2.184 17.242.221 13.713.059 12.677.011 12.346 0 9.709 0Zm0 4.723a4.986 4.986 0 1 0 4.986 4.986 4.986 4.986 0 0 0-4.986-4.986Zm0 8.222a3.236 3.236 0 1 1 3.236-3.236 3.236 3.236 0 0 1-3.236 3.236Zm5.183-9.584a1.165 1.165 0 1 0 1.164 1.165 1.166 1.166 0 0 0-1.164-1.164Z"/></svg>');
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: center;
}

.logo_facebook {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.319 20"><path d="M2.064 20H6.2V10h2.754l.365-3.446H6.2V4.828c0-.9.086-1.381 1.375-1.381H9.3V0H6.542C3.23 0 2.064 1.672 2.064 4.484v2.069H0V10h2.064Z"/></svg>');
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: center;
}

.logo_x {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.391 19.336"><path d="M16.847 0h3.28l-7.166 8.19 8.43 11.145h-6.6l-5.17-6.76L3.7 19.336H.422l7.665-8.761L0 0h6.768l4.673 6.178ZM15.7 17.372h1.818L5.781 1.86H3.83Z"/></svg>');
    background-repeat: no-repeat;
    background-size: auto 20px;
    background-position: center;
}

.logo_home {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.069 13.752"><path d="M4.311 7.686a8.576 8.576 0 0 0-.314 5.056c.075.458 8.083.207 8.083.207a8.919 8.919 0 0 0 0-5.262" fill="none" stroke="%23000" stroke-width="1.5"/><path data-name="Path 2" d="M.692 6.294c4.693-1.828 5.718-4.72 7.26-4.77s4.752 5.152 8.026 4.707" fill="none" stroke="%23000" stroke-width="1.5"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
}

.icon_cross {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.657 19.657"><path d="m2.829 2.829 14 14M16.828 2.829l-14 14" fill="none" stroke="%231191d7" stroke-linecap="round" stroke-width="4"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
}

.icon_euro {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.68 197.12"><path opacity="0.5" d="m136.64 165.06 4.9 22.26a4.114 4.114 0 0 1-.42 3.15 4.41 4.41 0 0 1-2.38 2.03l-.7.14a6.733 6.733 0 0 1-1.47.49q-.91.21-2.24.63t-3.01.77q-1.68.35-3.57.7t-4.2.7q-2.31.35-4.69.63t-5.11.42q-2.73.14-5.39.14-32.76 0-57.26-18.27t-33.32-49.21H4.48a4.305 4.305 0 0 1-3.15-1.33A4.305 4.305 0 0 1 0 125.16v-15.82a4.305 4.305 0 0 1 1.33-3.15 4.305 4.305 0 0 1 3.15-1.33h9.24q-.28-7.98.14-14.7H4.48A4.313 4.313 0 0 1 0 85.68V69.72a4.313 4.313 0 0 1 4.48-4.48H18.2a91.206 91.206 0 0 1 34.09-47.32Q77 0 108.36 0a111.617 111.617 0 0 1 27.16 3.22 5.327 5.327 0 0 1 2.8 2.1 4.621 4.621 0 0 1 .42 3.36l-6.02 22.26a4.1 4.1 0 0 1-1.96 2.73 4.012 4.012 0 0 1-3.36.35l-.56-.14q-.56-.14-1.61-.35l-2.45-.49-3.15-.49-3.64-.42-4.06-.35-4.13-.14a57.434 57.434 0 0 0-31.64 8.96 55.309 55.309 0 0 0-21 24.64h65.52a4.588 4.588 0 0 1 4.48 5.32l-3.36 15.96a4.09 4.09 0 0 1-4.48 3.64H49a125.932 125.932 0 0 0 0 14.7h64.26a4.373 4.373 0 0 1 3.5 1.68 4.547 4.547 0 0 1 .84 3.78L114.24 126a4.529 4.529 0 0 1-4.34 3.64H55.72a56.36 56.36 0 0 0 52.92 35.56q2.52 0 5.04-.21t4.69-.49q2.17-.28 4.13-.63t3.43-.7q1.47-.35 2.59-.63l1.68-.42.7-.28a4.187 4.187 0 0 1 3.64.28 4.291 4.291 0 0 1 2.1 2.94Z"/></svg>');
    background-repeat: no-repeat;
}