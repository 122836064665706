$col-accent: #FC504B;
$col-accent-2: #1191D7;
$col-bg: #EBEBEB;
$col-card: #F5F5F5;
$col-img-txt: #FFF;
$col-txt: #3A3E45;

$corners: 7px; // border-radius
$shadow: 0 5px 20px rgba(#000, 0.04);

@import "_typography",
"_icons",

"components/_buttons",
"components/_forms",
"components/_card",
"components/_header",
"components/_footer",
"components/_cookies-consent",

"pages/_page",
"pages/_home",
"pages/_projects",
"pages/_stories",
"pages/_updates",
"pages/_emissaries",
"pages/_contact",
"pages/_donate";

* {
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    background-color: $col-bg;
}

svg.blue {
    stroke: blue;
}

h1 {
    display: block;
    position: relative;
    text-align: center;
    left: 50%;
    top: 125px;
    transform: translateX(-50%);
    z-index: 1;
}

#cms {
    position: fixed;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}