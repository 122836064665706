main[data-template="emissaries"] {

    $colwidth: 600px;
    $gaps: 100px;
    padding-top: 125px;
    min-height: calc(100vh - 290px);

    h1 {
        top: auto;
    }

    #emissaries {
        max-width: $colwidth;
        margin-left: $gaps;

        article {
            display: grid;
            grid-template-columns: 240px 1fr;

            margin-bottom: 60px;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &>div {
                padding: 20px 25px;

                h2 {
                    margin: 0;
                    font-size: 24px;
                }
            }
        }
    }

    #apply {

        position: fixed;
        top: 62%;
        left: calc((100vw - ($gaps * 3 + $colwidth)) / 2 + $gaps * 2 + $colwidth);
        transform: translate(-50%, -50%);
        width: calc(100vw - ($gaps * 3 + $colwidth));
        max-width: $colwidth;

        h3 {
            color: $col-accent-2;
            text-align: center;
            margin-bottom: 15px;
        }

        &>p {
            text-align: center;
            max-width: 340px;
            margin: 0 auto;
        }

        &>button {
            display: block;
            margin: 20px auto 0;
            overflow: hidden;
        }

        .thankyou {
            text-align: center;

            h3 {
                margin-top: 0;
            }
        }
    }

    #application {

        height: 0;
        overflow: hidden;
        margin-top: 20px;

        fieldset {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 20px;
        }

        button[type="submit"] {
            display: block;
            margin: 30px auto 0;
        }
    }

    @media (max-width: 1500px) {

        $colwidth: 600px;
        $gaps: 50px;

        #volunteers {
            max-width: $colwidth;
            margin-left: $gaps;
        }

        #apply {

            left: calc((100vw - ($gaps * 3 + $colwidth)) / 2 + $gaps * 2 + $colwidth);
            width: calc(100vw - ($gaps * 3 + $colwidth));
            max-width: $colwidth;

        }

    }

    @media (max-width: 1350px) {

        $colwidth: 500px;
        $gaps: 50px;

        #volunteers {
            max-width: $colwidth;
            margin-left: $gaps;
        }

        #apply {

            left: calc((100vw - ($gaps * 3 + $colwidth)) / 2 + $gaps * 2 + $colwidth);
            width: calc(100vw - ($gaps * 3 + $colwidth));
            max-width: $colwidth;

        }

    }

    @media (max-width: 1160px) {

        $colwidth: 500px;
        $gaps: 50px;

        &>div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 50px;
            padding: 0 50px;
        }


        #volunteers {
            max-width: none;
            margin-left: 0;

            article {
                display: grid;
                grid-template-columns: 190px 1fr;

                margin-bottom: 30px;

                &>div {
                    padding: 15px 20px;

                    h2 {
                        margin: 0;
                        font-size: 24px;
                    }
                }
            }
        }

        #apply {
            position: relative;
            left: 0;
            width: auto;
            max-width: none;
            transform: none;
            top: 0;

        }

        #application {

            fieldset {
                grid-template-columns: 1fr;
                grid-gap: 0;
            }

            button[type="submit"] {
                display: block;
                margin: 30px auto 0;
            }
        }

    }

    @media (max-width: 990px) {

        &>div {
            display: block;
            padding: 0 100px;
        }

        #apply {
            margin-top: 60px;
        }

        #application {

            fieldset {
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 20px;
            }

        }

    }

    @media (max-width: 700px) {

        #application {

            fieldset {
                grid-template-columns: 1fr;
                grid-gap: 0;
            }

        }

    }

    @media (max-width: 650px) {

        &>div {
            padding: 0 50px;
        }

    }

    @media (max-width: 540px) {

        &>div {
            padding: 0 25px;
        }

    }

    @media (max-width: 480px) {

        #volunteers {

            article {
                display: block;

                img {
                    aspect-ratio: 1;
                }


                &>div {
                    padding: 15px 20px;

                    h2 {
                        margin: 0;
                        font-size: 24px;
                    }
                }
            }
        }

    }
}