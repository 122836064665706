main[data-template="donate"] {

    min-height: calc(100vh - 290px);
    padding-top: 250px;

    &>img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &>section {
        padding: 25px;
        max-width: 410px;
        position: relative;
        left: 64%;

        h1 {
            top: auto;
            left: auto;
            transform: none;
            text-align: left;
            margin: 0 0 10px;
        }

        p {
            color: $col-img-txt;
        }

        footer {
            position: relative;
            margin-top: 10px;
            display: flex;
            align-items: center;

            input {
                display: block;
                height: 50px;
                background-color: $col-card;
                border-radius: 25px;
                border: none;
                padding: 0 70px 0 35px;
                width: 200px;
                font-size: 21px;
                background-position: 12px center;
                background-size: 16px;
                outline: none;

                &::placeholder {
                    color: silver;
                }
            }

            button {
                display: block;
                transform: translateX(-50%);
            }
        }

        &.card {

            p {
                color: $col-txt;
            }

            footer {

                max-width: 50%;
                margin-top: -55px;
                right: 23px;
                position: absolute;
            }
        }
    }

    #payment-form {
        overflow: hidden;
        height: 0;

        [type="submit"] {
            margin-top: 30px;
        }
    }

    @media (max-width: 1200px) {

        &>section {
            left: 55%;
        }

    }

    @media (max-width: 920px) {

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
        }

        &>img {
            object-position: 15% 50%;
        }

        &>section {
            left: auto;
            margin: 0 auto;
        }

    }

    @media (max-width: 430px) {

        &>img {
            object-position: 10% 50%;


        }

        &>section {
            margin: 0 25px;
        }

    }

}