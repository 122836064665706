#cookies_consent {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #333;
    color: #FFF;
    padding: 0.25em 1em 1em;
    display: block;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;

    a {
        color: #FFF;
        text-decoration: underline;
    }

    .buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px 8px;
    }
}