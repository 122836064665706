fieldset {
    border: none;
    display: block;
    padding: 0;
}

.fld {
    margin-bottom: 15px;

    input, textarea {
        background-color: $col-card;
        border-radius: $corners;
        border: none;
        box-shadow: $shadow;
        display: block;
        width: 100%;
        font: 16px "Noto Sans", sans-serif;
        padding: 9px 10px;
        color: $col-txt;
        outline: none;
    }

    label {
        display: block;
        font-size: 16px;
        margin-bottom: 5px;
    }

    &.error {
        input, textarea {
            box-shadow: 0 0 0 2px rgba($col-accent, 0.5);
        }
    }

    .error_message {
        display: inline-block;
        margin-top: 5px;
        background-color: $col-accent;
        color: #FFF;
        letter-spacing: 0.03em;
        padding: 0.2em 0.6em;
        border-radius: 5px;
        transform: translateX(-1px);
    }
}