main[data-template="page"] {

    padding-top: 500px;
    min-height: calc(100vh - 290px);

    &>img {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }

    &>article {
        display: block;
        max-width: 780px;
        margin: 0 auto;
        padding: 30px 40px;

        h1 {
            top: auto;
            left: auto;
            transform: none;
            text-align: left;
        }

        h2, h3, h4, h5, h6 {
            margin-bottom: 0.4em;
        }

        figure {
            margin: 0;
            padding: 0;

            img {
                display: block;
                width: 100%;
                height: auto;
                margin: 0 auto;
                border-radius: 5px;
            }
        }
    }

    @media (max-width: 830px) {
    
        &>article {
            margin: 0 25px;
            padding: 30px 25px;
        }
    
    }

}