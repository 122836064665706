.btn {
    background-color: $col-accent;
    height: 60px;
    border: none;
    border-radius: 30px;
    padding: 0 30px 1px;
    color: #FFF;
    font-size: 21px;
    line-height: 59px;
    font-weight: 700;
    letter-spacing: 0.03em;
    font-family: 'Roboto Slab', sans-serif;
    cursor: pointer;

    &.blue {
        background-color: $col-accent-2;
    }
}