main[data-template="stories"] {

    min-height: calc(100vh - 290px);

    h1 {
        position: absolute;
    }

    &>div {
        display: flex;
        flex-wrap: wrap;

        article {
            height: 90vh;
            flex: 1 1 33%;
            display: block;
            max-width: 100%;
            position: relative;

            a {
                width: 100%;
                height: 100%;
                padding: 25px 45px;
                display: grid;
                grid-template-rows: 1fr auto;
                align-items: end;
                color: $col-img-txt;

                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(0deg, rgba(0, 0, 0, .35) 0%, rgba(0, 0, 0, 0) 100%);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    z-index: -1;
                }

                h2 {
                    margin: 0;
                    padding-bottom: 15px;
                    position: relative;
                    color: $col-img-txt;

                    &::after {
                        content: "";
                        display: block;
                        width: 95px;
                        height: 6px;
                        background-color: $col-accent-2;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }

                p {
                    font-size: 16px;
                }
            }
        }
    }


    @media (max-width: 1200px) {

        &>div {

            a {
                flex: 1 1 50%;

                article {
                    padding: 5px 25px;
                }
            }
        }
    }

    @media (max-width: 800px) {

        &>div {

            a {

                article {

                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }
    @media (max-width: 600px) {

        &>div {

            display: block;

            a {

                height: 75vh;

                article {

                    p {
                        font-size: 13px;
                    }
                }
            }
        }
    }


}