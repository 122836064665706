main[data-template="projects"] {

    min-height: calc(100vh - 40px);
    padding-top: calc(58vh);


    h1 {
        position: fixed;
        pointer-events: none;
    }

    #location_background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    #projects_map {
        background-color: $col-bg;
        position: fixed;
        top: 160px;
        width: 100%;

        &>img {
            width: 100%;
            height: auto;
            pointer-events: none;
            user-select: none;
        }

        nav {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            figure {
                position: absolute;
                margin: 0;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    pointer-events: none;
                    user-select: none;
                }

                figcaption {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: translate(95%, 75%);
                    font-size: 24px;
                    font-family: "Roboto Slab", sans-serif;
                    font-weight: 700;
                    color: $col-accent;
                    line-height: 1.15em;
                    white-space: nowrap;
                }
            }
        }

        &.masked {
            width: 300%;
            transform: translate3d(calc(-46.8% + (100vw * 0.15)), calc(50vh - 37% - (54px * 3 / 2) - 160px), 0);
        }
    }

    #map_wrap {
        background-color: $col-bg;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-mask-image: url('../img/mask-white.png');
        mask-image: url('../img/mask-white.png');
        mask-size: 100% 110%;
        mask-repeat: no-repeat;
        mask-position: 15% 50%;
        transition: mask-size 1s cubic-bezier(.5, 0, 0, 1), mask-position 1s cubic-bezier(.5, 0, 0, 1);

        &.masking {
            mask-position: 15vw 50%;
            mask-size: 15vw 15vw;
            transition: mask-size 1s cubic-bezier(.5, 0, 0, 1), mask-position 1s cubic-bezier(.5, 0, 0, 1);
        }
    }

    &>section.card {
        display: none;
        max-width: 780px;
        z-index: 0;
        position: relative;
        margin: 0 auto 0;
        padding: 20px 25px;
        overflow: visible;

        &.active {
            display: block;
        }

        h2 {
            color: $col-txt;
            font-size: 21px;
            padding: 1px;
            margin-top: 0;

            span {
                display: block;
                color: $col-accent;
                font-size: 48px;
                margin: 15px 0 10px;
                line-height: 1em;
            }
        }

        p {
            margin: 1em 0 0;
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            height: 46px;
            width: 46px;
            border-radius: 50%;
            border: none;
            transform-origin: center;
            transform: translate(100%, -100%);
            background-size: 40%;
            cursor: pointer;
            transition: transform .4s cubic-bezier(0.5, 0, 0, 1);

            &:hover {
                transform: translate(100%, -100%) scale(1.15);
                transition: transform .4s cubic-bezier(0.5, 0, 0, 1);
            }

            @media (max-width: 930px) {

                transform: translate(10%, -130%);

                &:hover {
                    transform: translate(10%, -130%) scale(1.15);
                }
            }
        }

        @media (max-width: 830px) {

            margin: 0 25px;

        }
    }

    @media (max-width: 1024px) {

        #projects_map {

            nav {
                figure {
                    figcaption {
                        font-size: 18px;
                    }
                }
            }
        }

    }

    @media (max-width: 800px) {

        #projects_map {

            nav {
                figure {
                    figcaption {
                        font-size: 16px;
                    }
                }
            }
        }

    }

    @media (max-width: 700px) {

        h1 {
            width: 100%;
            top: 100px;
        }

        #projects_map {

            width: 140%;
            left: calc(-20%);
            top: 140px;

            nav {
                figure {
                    figcaption {
                        font-size: 14px;
                    }
                }
            }
        }

        &>section.card {

            h2 {
                font-size: 16px;

                span {
                    font-size: 28px;
                    margin: 5px 0 5px;
                }
            }
        }

    }

    @media (orientation: portrait) {

        height: auto;
        min-height: 70vh;

        #projects_map {
            width: 140%;
            left: calc(-20%);
            top: 25%;
        }

        #map_wrap {

            &.masking {
                mask-position: 10vw 50%;
                mask-size: 25vw 25vw;
            }
        }
    }
}

@media (orientation: portrait) {

    body:has([data-template="projects"]) {
        display: grid;
        grid-template-rows: 1fr auto;
        min-height: 100vh;
    }

}