body>header {

    $logo_height: 155px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: $logo_height;
        background: linear-gradient(180deg, rgba(0, 0, 0, .15) 0%, rgba(0, 0, 0, 0) 100%);
        pointer-events: none;
    }

    .logo {
        position: fixed;
        top: 0;
        left: 25px;
        width: 160px;
        height: $logo_height;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $col-card;
        box-shadow: $shadow;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;

        img {
            width: 106px;
            height: auto;
        }
    }

    .menu {
        position: fixed;
        top: 25px;
        right: 20px;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: end;
        align-items: center;
        grid-gap: 30px;

        nav {
            position: relative;
            display: flex;
            align-items: center;
            background-color: $col-card;
            border-radius: 23px;
            padding: 0 0 0 5px;
            height: 46px;
            line-height: 46px;

            button {
                display: none;
            }

            a {
                display: block;
                font-family: 'Roboto Slab', sans-serif;
                font-size: 16px;
                color: $col-txt;
                padding: 0 18px;

                &.logo_home {
                    display: block;
                    padding: 0;
                    margin-right: 10px;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    background-size: 16px 16px;
                    background-color: #fff;
                    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.07);
                }

                &.donate {
                    margin-left: 10px;

                }

                &.btn {
                    display: block;
                    color: #FFF;
                    padding: 0 30px;
                }
            }

            .highlighter {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 4px;
                background-color: $col-accent;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
                transform: translate(-50%, 100%);
            }
        }
    }

    .social-media {
        display: flex;
        gap: 10px;

        a {
            width: 46px;
            height: 46px;
            box-shadow: $shadow;
            background-color: $col-card;
            border-radius: 23px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    @media (max-width: 1200px) {

        .logo {
            width: 120px;
            height: 110px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            img {
                width: 80px;
                height: auto;
            }
        }

        .menu {

            nav {
                position: fixed;
                display: flex;
                justify-content: center;
                width: 166px;
                height: 46px;
                right: 25px;
                top: 25px;
                line-height: 46px;
                transition: all .6s cubic-bezier(0.5, 0, 0, 1);

                button {
                    position: fixed;
                    top: 30px;
                    right: 146px;
                    display: block;
                    width: 36px;
                    height: 36px;
                    border: none;
                    border-radius: 50%;
                    background-size: 16px 16px;
                    background-color: #fff;
                    cursor: pointer;

                    svg {
                        display: block;
                        color: $col-txt;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);

                        line {
                            transition: transform 0.3s ease, opacity 0.3s ease;
                        }
                    }
                }

                a,
                a.logo_home {
                    visibility: hidden;
                    opacity: 0;

                    &:last-of-type {
                        display: block;
                        position: fixed;
                        top: 18px;
                        right: 20px;
                        visibility: visible;
                        opacity: 1;
                    }
                }

                .highlighter {
                    display: none;
                }

                &.active {
                    position: fixed;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 100vh;
                    right: 0;
                    top: 0;
                    border-radius: 0;

                    transition: all 0.6s cubic-bezier(0.5, 0, 0, 1);

                    button {

                        svg {

                            line {
                                transition: transform 0.3s ease, opacity 0.3s ease;
                            }

                            line:nth-child(2) {
                                transform-origin: 4% 0;
                                transform: rotate(45deg) translate(5px, -5px);
                            }

                            line:nth-child(1) {
                                opacity: 0;
                            }

                            line:nth-child(3) {
                                transform-origin: 4% 100%;
                                transform: rotate(-45deg) translate(5px, 5px);
                            }
                        }
                    }

                    a,
                    a.logo_home {
                        visibility: visible;
                        margin-right: 0;
                        margin-bottom: 10px;
                        opacity: 1;
                        transition: opacity .6s cubic-bezier(0.5, 0, 0, 0.5);
                    }
                }
            }
        }

        .social-media {
            position: fixed;
            right: 220px;
            top: 25px;
            z-index: 1;

            a {
                width: 46px;
                height: 46px;
                box-shadow: $shadow;
                background-color: $col-card;
                border-radius: 23px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    @media (max-width: 600px) {

        .logo {
            width: 90px;
            height: 90px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            img {
                width: 70px;
                height: auto;
            }
        }

        .social-media {
            display: none;
            position: fixed;
            left: 20px;
            top: 28px;
            width: 45%;

            a {
                width: 40px;
                height: 40px;
                background-color: #fff;
                box-shadow: $shadow;
            }
        }

    }
}