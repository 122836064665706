main[data-template="updates"] {

    padding-top: 125px;

    h1 {
        top: auto;
    }

    &>div {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 50px;
        padding: 0 50px;

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            color: $col-txt;

            &>* {
                flex: 1;
            }

            &>img {
                display: block;
                aspect-ratio: 1;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .text {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }

            h3 {
                margin: 0;
                padding: 10px 15px;
            }

            p {
                margin: auto 0 15px;
                padding: 0 15px;
            }
        }

        @media (max-width: 600px) {

            padding: 0 25px;

        }

    }

}