main[data-template="home"] {

    .hero {
        width: 100%;
        height: 100vh;
        position: relative;

        &>img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80%;
            background: linear-gradient(0deg, rgba(0, 0, 0, .36) 0%, rgba(0, 0, 0, 0) 100%);
        }

        h1 {
            left: 16px;
            top: auto;
            text-align: left;
            transform: none;
            position: relative;
            font-size: 72px;
            margin: 0 0 20px;
            color: $col-img-txt;
            text-shadow: 0 0 6px rgba(#000, 0.3), 0 8px 16px rgba(#000, 0.3);
        }

        &>section {
            $margins: 25px;

            position: absolute;
            bottom: $margins;
            left: $margins;
            width: calc(100% - ($margins * 2));
        }

        .panel {
            padding: 32px 43px 36px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 60px;

            h2 {
                margin-top: 0;
                font-size: 18px;
                text-align: left;
                margin: 0 0 15px;
            }

            p {
                margin: 0;
            }
        }

        @media (max-width: 1024px) {

            h1 {
                font-size: 48px;
            }

            .panel {
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 30px;

                h2 {
                    font-size: 16px;
                    margin: 0 0 10px;
                }
            }
        }

        @media (max-width: 1024px) and (orientation: landscape) {

            height: 125vh;

        }

        @media screen and (max-width: 800px) {

            h1 {
                left: 0;
            }
        }

        @media screen and (max-width: 600px) {

            height: 150vh;

            &>img {
                height: 100vh;
            }

            &::before {
                display: none;
            }

            &>section {
                bottom: 25px;
            }

            .panel {
                grid-template-columns: 1fr;
                grid-row-gap: 23px;
                padding: 20px 25px 25px;
            }
        }
    }

    .quote {
        position: relative;
        min-height: 50vh;

        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: -1;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(0, 0, 0, .6) 0%, rgba(0, 0, 0, 0) 100%);
        }

        blockquote {
            display: block;
            width: 35vw;
            position: relative;
            left: 140px;
            padding: 160px 0;
            margin: 0;

            p {
                color: $col-img-txt;
                font-size: 18px;
                font-family: 'Roboto Slab', sans-serif;
                position: relative;
                line-height: 1.6em;

                &::before {
                    content: '“';
                    font-size: 194px;
                    position: absolute;
                    top: .3em;
                    left: -.41em;
                    color: $col-accent;
                }

                &::after {
                    content: '”';
                    font-size: 194px;
                    position: absolute;
                    bottom: -0.41em;
                    right: -.3em;
                    color: $col-accent;
                }
            }

            footer {
                color: $col-img-txt;
                font-size: 18px;
                font-family: 'Roboto Slab', sans-serif;
                position: relative;
                line-height: 1.6em;

                cite {
                    display: block;
                }
            }
        }

        @media (max-width: 1280px) {

            blockquote {
                width: 35vw;
                position: relative;
                left: 80px;
                padding: 80px 0;
                margin: 0;

                p {
                    font-size: 16px;

                    &::before,
                    &::after {
                        font-size: 170px;
                    }
                }
            }

        }

        @media (max-width: 1024px) {

            blockquote {

                p {
                    font-size: 14px;

                    &::before,
                    &::after {
                        font-size: 150px;
                    }
                }

                footer {
                    font-size: 14px;
                }
            }

        }

        @media (max-width: 700px) {

            blockquote {

                width: 65vw;
                left: 25px;
                padding: 40px 0;

                p {
                    font-size: 14px;

                    &::before,
                    &::after {
                        font-size: 100px;
                    }
                }

                footer {
                    font-size: 14px;
                }
            }

        }
    }

    h2 {
        margin: 50px;
        text-align: center;
    }

    article.card {
        position: relative;
        display: block;
        height: 460px;
        max-width: 1200px;
        margin: 0 auto 40px;

        img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &>div {
            display: inline-grid;
            grid-template-columns: 75px auto;
            grid-template-rows: auto auto;
            grid-column-gap: 30px;
            grid-row-gap: 10px;
            position: absolute;
            top: 50%;
            left: 10%;
            height: auto;
            width: 410px;
            transform: translate(0, -50%);
            padding: 40px;

            img {
                grid-column: 1 / 2;
                grid-row: 1 / 3;
                align-self: center;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }

            h3 {
                grid-column: 2 / 3;
                margin: 0;
                color: $col-accent-2;
            }

            p {
                grid-column: 2 / 3;
                margin: 0;
            }
        }

        @media (max-width: 1320px) {

            margin: 0 60px 40px;

            &>div {
                grid-template-columns: 60px auto;
                grid-column-gap: 20px;
                grid-row-gap: 10px;
                top: 65%;
                left: 5%;
                width: 375px;
                padding: 30px;
            }
        }

        @media (max-width: 1024px) {

            &>div {
                top: auto;
                bottom: 60px;
                left: 60px;
                transform: none;
            }
        }

        @media (max-width: 780px) {

            height: 350px;
            margin: 0 25px 40px;

            &>div {
                bottom: 25px;
                left: 25px;
                max-width: calc(100% - 50px);
                grid-template-columns: 35px auto;
                padding: 25px;
            }
        }

        @media (max-width: 780px) and (orientation: portrait) {

            height: auto;
            display: grid;
            grid-template-rows: 1fr auto;
            grid-template-columns: 1fr;

            img {

                position: relative;
                top: 0;
                left: 0;
                object-fit: cover;
            }

            &>div {
                position: relative;
                bottom: 0;
                left: 0;
                width: 100%;
                max-width: none;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                grid-template-columns: 35px auto;
                grid-column-gap: 20px;
                grid-row-gap: 10px;
                padding: 25px;
            }
        }
    }
}